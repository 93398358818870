import { Directive, Input } from '@angular/core';
import { ValidatorFn, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';


// This Validator checks the names against reserved or non-applicable names such as admin, 
// or application specific names such as edudate edudater... etc

export function nameValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? {invalid: {value: control.value}} : null;
  };
}
@Directive({
    selector: '[hahNameValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NameValidatorDirective, multi: true }],
    standalone: true
})
export class NameValidatorDirective {
  @Input('hahForbiddenName') forbiddenName = '';
  constructor() { }
  validate(control: AbstractControl): ValidationErrors | null {
    return this.forbiddenName ? nameValidator(new RegExp(this.forbiddenName, 'i'))(control): null;
  }
}
