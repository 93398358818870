import { Directive, Input } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';




/* The Validator takes the signature function "ValidateorFn" (control:AbstractControl ) => {return ValidationErrors|null}
*  Wrapper function is needed to nest the ValidatorFn so that passing parameters can be utilized, ex field1,field2...
*  The caller will get back a valid signature function ((control:AbstractControl) => {return ValidationErrors | null}) 
*  this defined function can access to the passing parameters from the wrapper function
*  
*  HAH Jun 20 2022
*/
export function crossFieldValidator(field1 : string, field2: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const control1 = control.get(field1);
    const control2 = control.get(field2);
    return control1 && control2 && control1.value !== control2.value ? { fieldsmismatch: true } : null;
  };
};
@Directive({
    selector: '[hahCrossFieldValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CrossFieldValidatorDirective, multi: true }],
    standalone: true
})
export class CrossFieldValidatorDirective implements Validator{
  @Input('hahCrossFieldMatchValidator') controls : string[] =[];
  constructor() { }
  validate(control: AbstractControl): ValidationErrors | null {
    return crossFieldValidator(this.controls[0],this.controls[1]);
  }

}
